body {
  --scrollbar-width: calc(100vw - 100%);
  --aside-width: 0px;
  --header-height: 0px;

  width: 100vw;
  min-height: 100vh;
  position: relative;
  right: calc(-1 * var(--scrollbar-width));
}

#router-overlay {
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: var(--aside-width);
  height: calc(100vh - var(--header-height));
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light, rgb(255, 255, 255));
  opacity: 1;
  transition: opacity 1s;
  z-index: 1100;
}

#router-overlay > div, .overlay-loading {
  color: rgb(130, 223, 177);
    font-size: 90px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 72px auto;
    position: relative;
    transform: translateZ(0);
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

.overlay-loading {
    font-size: 70px;
}
/* rtl:begin:ignore */
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
      -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes round {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* rtl:end:ignore */
